<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="contest-apply step2">
          <div class="step">
            <ul>
              <li>1. 지원서 항목 입력</li>
              <li>2. 지원서 확인</li>
            </ul>
          </div>
          <div class="title">제목이 노출되는 영역 입니다.</div>
          <div class="form">
            <form name="">
              <table>
                <tbody>
                  <tr>
                    <th>{{example}}</th>
                    <td>
                      단일아이템
                    </td>
                  </tr>
                  <tr>
                    <th>{{example}}</th>
                    <td>
                      아이템1, 아이템2, 아이템3
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="scroll-box">
                <div>
                  서약서 내용 출력
                </div>
              </div>
              <div class="chk-box">
                <input type="checkbox" name="" id="exchk" />
                <label for="exchk">위의 약관에 동의합니다.</label>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-submit">지원완료</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: 'ContestApplyStep2',
  components: {
  },
  data() {
    return {
      example: '항목명노출',
      example2: '항목명노출2',
    };
  }
};
</script>

<style>
</style>
